import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Rotate = () => (
  <StaticQuery
    query={graphql`
      query {
        line: file(relativePath: { eq: "rotate.png" }) {
          childImageSharp {
            fixed(width: 138) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="mobile-portrait">
        <p>Please rotate your device</p>
        {data.line.childImageSharp.fixed && (
          <Img alt="Please rotate your device" fixed={data.line.childImageSharp.fixed} fadeIn={false} loading="eager" />
        )}
      </div>
    )}
  />
);

export default Rotate;
